fieldset {
  padding: 10px !important;
}

.body {
  display: block;
  flex: 1;
  min-height: 100vh;
  background: #fff !important;
  font-family: auto !important;
  font-size: inherit !important;
  color: inherit !important;
  max-height: unset !important;
  overflow: auto !important;
  display: block !important;
  padding: 15px !important;
}
