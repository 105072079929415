@import "~react-image-gallery/styles/css/image-gallery.css";

.image-item {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
}

.image-item__image-wrapper {
  width: 100%;
  object-fit: cover;
  height: 85px;
}

.upload__button-image-upload {
  background-color: #0a0a23;
  height: 50px;
  width: 515px;
  color: #fff;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
}

button {
  margin-top: 10px;
  width: 100%;
}
